.project-iphone-mockup{
    padding: 80px;
    background-color: black;
    border-radius: 12px;
}

.Project1{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.Project1 .contact .Contact-desc h1, .Project1 .contact .Contact-call a{
    font-size: 38px;
}



  /*media Query*/
  @media only screen and (min-width: 300px) and (max-width: 600px){

    .project-iphone-mockup{
      padding: 20px;
        margin: 0 15px;
    }
  
    .Project1 .contact .Contact-desc h1, .Project1 .contact .Contact-call a{
        font-size: 30px;
    }
    

    
  
  
  }  