.navbar {
  padding: 30px 40px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.radio-inputs {
  display: flex;
  flex-wrap: wrap;
  border-radius: 2rem;
  backdrop-filter: blur(16px);
  background-color: rgba(232, 229, 228, 0.5);
  box-sizing: border-box;
  padding: 0.25rem;
  width: fit-content;
  font-size: 12px;
  margin: 0 auto;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  border: none;
  padding: .5rem 1.45rem;
  color: black;
  transition: all .2s ease-in-out;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
}

.radio-inputs .radio .name.active {
  background-color: #fff;
}

.radio-inputs .radio input:checked ~ .name {
  background-color: #fff;
}








/*Media Query*/
@media only screen and (min-width: 300px) and (max-width: 600px){
  .radio-inputs{
      flex-wrap: nowrap
  }
}