.hero-tag-desc{
    font-size: 70px;
    text-align: center;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 1.2em;
    padding: 2.5em 2em .7em 2em;
    transition: opacity 200ms linear;
}

.hero-expertise{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: opacity 200ms linear;
}

.hero-expertise .tag{
    background-color: #F3F2F1;
    border-radius: 30px;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;
}




.hero-tag-desc{
    opacity: 0;
    transform: translateY(15%);
    transition: all 0.5s ease-out 0.2s;
}

.hero-expertise{
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.7s ease-out 0.3s;
}



.hero-description.hero-slidein .hero-tag-desc,
.hero-description.hero-slidein .hero-expertise {
    opacity: 1;
    transform: translateY(0);
}

.images{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
    grid-column-gap: 20px;
    padding: 80px 40px 20px;
    opacity: 0;
    transform: translateY(5%);
    transition: all 0.4s ease-out 0.1s;
}

.images.project-slide-in {
    opacity: 1;
    transform: translateY(0);
}

.Hero .contact .Contact-desc h1, .Hero .contact .Contact-call a{
    font-size: 38px;
}


/*media query*/
@media only screen and (min-width: 300px) and (max-width: 400px){
   
    .hero-tag-desc {
        font-size: 36px;
        padding: 4em .5em .7em .5em;
    }

    .hero-expertise{
        font-size: 10px;
        padding: 12px;
    }
    
    .hero-expertise .tag {
        font-size: 11px;
        padding: 2px 5px;
    }

    .images{
        display: flex;
        flex-direction: column;
        padding: 80px 20px 20px;
    }

    .Hero .contact .Contact-desc h1, .Hero .contact .Contact-call a{
        font-size: 30px;
    }

   

}
@media only screen and (min-width: 400px) and (max-width: 650px){
   
    .hero-tag-desc {
        font-size: 40px;
        padding: 4em .3em .7em .3em;
    }

    .hero-expertise{
        padding: 20px;
    }
    
    .hero-expertise .tag {
        padding: 2px 5px;
    }

    .images{
        display: flex;
        flex-direction: column;
        padding: 80px 20px 20px;
    }

   

}