.Project2{
    display: flex;
    flex-direction: column;
    gap: 15px;

}


.laptop-image{
    height: 540px;
    padding: 0 30px;
}

.laptop-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.project-grid-images{
    display: flex;
    flex-direction: row;
    padding: 0 30px;
    gap: 15px;
    justify-content: center;
    
}

.project-grid-images .image{
   
    width: 100%;
    height: 540px;
    padding: 50px;
    background: #F4F3F1;
    border-radius: 12px;

}

.project-grid-images .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    
}

.project-overview{
    padding: 30px;

}

.project-overview p{
   
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.4em;
    text-align: left;
    text-decoration: none;
    text-transform: none;
}

.project-iphone-mockup{
    margin: 0px 30px;
}

.Project2 .contact .Contact-desc h1, .Project2 .contact .Contact-call a{
    font-size: 38px;
}



/*Media Query*/
@media only screen and (min-width: 300px) and (max-width: 600px){
    .project-grid-images{
        padding: 0 15px;
        gap: 10px;
    }

    .project-grid-images .image {
        
        height: 200px;
        padding: 10px;
    }

    .laptop-image{
        padding: 0 15px;
        height: 300px;
    }

    
.Project2 .contact .Contact-desc h1, .Project2 .contact .Contact-call a{
    font-size: 30px;
}

  }