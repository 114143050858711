.circle-close-icon {
    border-radius: 100px;
    backdrop-filter: blur(16px);
    background-color: rgba(232, 229, 228, 0.5);
    box-sizing: border-box;
    padding: 0.25rem;
    width: 48px;
    height: 48px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    z-index: 1000;
    
  transition: background-color 0.3s ease;
  }
  .circle-close-icon:before, .circle-close-icon:after {
    content: '';
    /* position: absolute; */
    width: 1px;
    height: 30%;
    background-color: #000;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    
  transition: background-color 0.3s ease;
  }
  .circle-close-icon:before {
    
    transform: translate(80%, 0%) rotate(45deg);
  }
  .circle-close-icon:after {
    transform: translate(0%, 0%) rotate(-45deg);
  }

  .circle-close-icon:hover {
    background-color: #000; /* Change background color on hover */
  }
  
  .circle-close-icon:hover:before,
  .circle-close-icon:hover:after {
    background-color: #fff; /* Change stroke color on hover */
  }

  .Project3{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }



  .project-quote{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 140px;
    gap: 20px;
  }

  .project-quote h2{
    
    font-size: 70px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -2.2px;
    line-height: 1.2em;
    margin-bottom: 0;
    text-align: center;
    color: #000000;
    text-decoration: none;
    text-transform: none;
    /* opacity: 0;
    transform: translateY(50%);
    transition: all 0.5s ease-out 0.2s; */
  }

  .project-quote .tag{
    background-color: #F3F2F1;
    border-radius: 30px;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;
    /* opacity: 0;
    transform: translateY(90%);
    transition: all 0.5s ease-out 0.4s; */
  }

  .Project3 .project-grid-images .image{
    height: 200px;
  }

  
.Project3 .contact .Contact-desc h1, .Project3 .contact .Contact-call a{
  font-size: 38px;
}


  
/* 
  .laptop-image img{
    
    height: 0%;
    transition: height 1.3s ease;
  transition-timing-function: cubic-bezier(.85,0,.15,1);
  } */


  /* .Project3.slide-in .laptop-image img{
    
    height: 100%;
    transition: height 1.3s ease;
  transition-timing-function: cubic-bezier(.85,0,.15,1);
  }


  .Project3.slide-in .project-quote h2, .Project3.slide-in .project-quote .tag{
    opacity: 1;
    transform: translateY(0);
  } */




  /*media Query*/
@media only screen and (min-width: 300px) and (max-width: 600px){

  .project-quote{
    padding: 70px 10px;
      
  }

  .project-quote h2{
    font-size: 30px;
    letter-spacing: -0.2px;
  }

  .project-overview{
    padding: 20px;
  }
 
  .project-overview p{
      font-size: 13px;
  }

  .Project3 .project-grid-images .image{
    height: auto;
    border-radius: 8px;
  }

  .Project3 .project-grid-images .image img{
    border-radius: 0px;
  }

  
.Project3 .contact .Contact-desc h1, .Project3 .contact .Contact-call a{
  font-size: 30px;
}



  



}
