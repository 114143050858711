

.project-container {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.project-nav-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(7px) saturate(160%);
    background-color: rgba(134, 134, 134, 0.20);
    color: white;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.project-container:hover .project-nav-container {
    opacity: 1;
}

.project-nav-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    align-items: center;
}

.project-name {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}

.icon {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%) translateY(50%);
    transition: transform 0.3s ease-in-out;
}

.project-container:hover .icon {
    transform: translate(0);
}


.icon-image {
    width: 15px;
    height: 15px;
}





/*media query*/

@media only screen and (min-width: 300px) and (max-width: 400px){
    .project-container{
        height: 250px;
    }

    .project-nav-header{
        padding: 10px;
    }

    .project-name{
        font-size: 14px;
    }

    .icon{
        width: 30px;
        height: 30px;
    }

    .icon-image{
        width: 12px;
        height: 12px;
    }

}  


@media only screen and (min-width: 400px) and (max-width: 650px){
    .project-container{
        height: 300px;
    }

}  