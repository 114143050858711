.contact {

    align-items: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    gap: -10px;
    flex-direction: column;

    height: 100vh;
    /* padding: 40px; */
    width: -webkit-fill-available;



}

.Contact-desc h1,
.Contact-call a {
    font-size: 62px;
    font-weight: 500;
    letter-spacing: -1px;
    margin: 0;

}

.Contact-call a {

    text-decoration: none;
    color: #7F7F7F;
    transition: opacity 200ms linear;

}

.Contact-call a:hover {
    opacity: .6;
}


.Contact-desc {
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.5s ease-out 0.2s;
}

.Contact-call{
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.6s ease-out 0.3s;

}

.contact.slide-in .Contact-desc,
.contact.slide-in .Contact-call {
    opacity: 1;
    transform: translateY(0);
}



/*Media Query*/



@media only screen and (min-width: 300px) and (max-width: 350px){
   
    .Contact-desc h1, .Contact-call a {
        text-align: center;
        font-size: 30px;
    }




}

@media only screen and (min-width: 350px) and (max-width: 400px){
   

    .Contact-desc h1, .Contact-call a {
        text-align: center;
        font-size: 30px;
    }




}


@media only screen and (min-width: 400px) and (max-width: 600px){
   
    .Contact-desc h1, .Contact-call a {
        text-align: center;
        font-size: 42px;
    }




}