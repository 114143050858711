.hero-greeting-name{
    font-size: 70px;
    text-align: center;
    font-weight: 500;
    letter-spacing: -2.2px;
    line-height: 1.2em;
    padding: 2.5em 2em .7em 2em;
}

.hero-image{
    padding: 60px 40px 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.hero-image img{
    width: 100%;
    height: 400px;
    
    border-radius: 12px;
    object-fit: cover;
}

.hero-about{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 140px 40px 0px;
}

.hero-about .tag{
    background-color: #F3F2F1;
    border-radius: 30px;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;

}

.hero-about h2{
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 1.4em;
    text-align: center;
    padding: 10px 100px;
    margin: 0;
    width: 700px;
}

.hero-greeting-name{
    opacity: 0;
    transform: translateY(15%);
    transition: all 0.5s ease-out 0.2s;
}

.hero-image{
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.7s ease-out 0.3s;
}

.profile.profile-slidein .hero-greeting-name,
.profile.profile-slidein .hero-image{
    opacity: 1;
    transform: translateY(0);

}

.hero-experience{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 140px 40px 0px;
    gap: 30px;
}

.hero-exp{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.hero-experience .tag{
    background-color: #F3F2F1;
    border-radius: 30px;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;

}

.hero-experience h2{
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 1.4em;
    text-align: center;
    padding: 10px 100px;
    margin: 0;
    width: 700px;
}


.experience-container{
    display: flex;
    flex-direction: row;
    gap: 30px;
}




.experience{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.experience p{
    font-size: 14px;
    margin: 10px;
    color: #828282;
}

.experience h3{
    font-size: 22px;
    margin: 0;
    font-weight: 500;
}

.hero-skills{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 140px 40px;
    gap: 10px;
}

.skill-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.skill-container .tag{
    background-color: #F3F2F1;
    border-radius: 30px;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 14px;   
}

.skill-container h2{
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 1.4em;
    text-align: center;
    padding: 10px 100px;
    margin: 0;
    width: 700px;
}

.skills{
    display: flex;
    gap: 20px;
}

.skills p{
    color: #828282;
    font-weight: 500;

}

.profile .contact .Contact-desc h1, .profile .contact .Contact-call a{
    font-size: 38px;
}





/*Media Query*/
@media only screen and (min-width: 300px) and (max-width: 400px){
   
    .hero-greeting-name{
        font-size: 40px;
        padding: 4.5em 0.5em 1.7em 0.5em;
    }

    .hero-image{
        flex-direction: column;
        padding: 20px 20px 30px;
    }

    .hero-image img{
        height: 250px;
    }

    .hero-about{
        padding: 80px 20px 0px;
    }

    .hero-about h2{
        font-size: 24px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .hero-experience{
        padding: 80px 40px 0px;
    }

    .hero-experience h2 {
        font-size: 24px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .hero-skills {
        padding: 80px 40px 30px;
    }

    .skill-container h2 {
        font-size: 24px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .skills {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .experience p{
        text-align: center;
        font-size: 12px;
    }

    .skills p{
        margin: 0;
    }

    .profile .contact .Contact-desc h1, .profile .contact .Contact-call a{
        font-size: 30px;
    }

}


@media only screen and (min-width: 400px) and (max-width: 650px){
   
    .hero-greeting-name{
        font-size: 40px;
        padding: 4.5em 0.5em 1.7em 0.5em;
    }

    .hero-image{
        flex-direction: column;
        padding: 20px 20px 30px;
    }

    .hero-image img{
        height: 250px;
    }

    .hero-about{
        padding: 80px 40px 0px;
    }

    .hero-about h2{
        font-size: 24px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .hero-experience{
        padding: 80px 30px 0px;
    }

    .hero-experience h2 {
        font-size: 24px;
        padding: 10px 0px;
        margin: 0;
        width: fit-content;
    }

    .hero-skills {
        padding: 80px 40px 30px;
    }

    .skill-container h2 {
        font-size: 24px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .skills {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .experience p{
        text-align: center;
    }

    .skills p{
        margin: 0;
    }

    .profile .contact .Contact-desc h1, .profile .contact .Contact-call a{
        font-size: 30px;
    }


}


@media only screen and (min-width: 650px) and (max-width: 850px){
   
    .hero-greeting-name{
        font-size: 45px;
        padding: 4.5em 0.5em 1.7em 0.5em;
    }

    .hero-image{
        flex-direction: row;
        padding: 20px 20px 30px;
    }

    .hero-image img{
        height: 250px;
    }

    .hero-about{
        padding: 100px 40px 0px;
    }

    .hero-about h2{
        font-size: 25px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .hero-experience{
        padding: 100px 40px 0px;
    }

    .hero-experience h2 {
        font-size: 25px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .hero-skills {
        padding: 100px 40px 30px;
    }

    .skill-container h2 {
        font-size: 25px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .skills {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .experience p{
        text-align: center;
    }

    .skills p{
        margin: 0;
    }


}


@media only screen and (min-width: 850px) and (max-width: 950px){
   
    .hero-greeting-name{
        font-size: 50px;
        padding: 4.5em 0.5em 1.7em 0.5em;
    }

    .hero-image{
        flex-direction: row;
        padding: 20px 20px 30px;
    }

    .hero-image img{
        height: 300px;
    }

    .hero-about{
        padding: 110px 40px 0px;
    }

    .hero-about h2{
        font-size: 26px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .hero-experience{
        padding: 110px 40px 0px;
    }

    .hero-experience h2 {
        font-size: 26px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .hero-skills {
        padding: 110px 40px 30px;
    }

    .skill-container h2 {
        font-size: 26px;
        padding: 10px 10px;
        margin: 0;
        width: fit-content;
    }

    .skills {
        display: flex;
        gap: 20px;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .experience p{
        text-align: center;
    }

    .skills p{
        margin: 0;
    }

}