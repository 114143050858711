body {
  margin: 0;
  
  font-family: "Neue Montreal", serif;
  /* font-family: 'General Sans', sans-serif; */
  /* font-family: 'Public Sans', sans-serif; */
  /* font-family: 'Satoshi', sans-serif; */
}


 
*::-webkit-scrollbar{
  display: none;
}

@font-face {
  font-family: 'Neue Montreal';;
  src: url('./fonts/NeueMontreal-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('./fonts/NeueMontreal-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}



@font-face {
  font-family: 'Neue Montreal';
  src: url('./fonts/NeueMontreal-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
