
.footer{
    /* padding: 30px 40px; */
    /* position: fixed; */
    bottom: 0px;
    width: 100%;
    
}

.footer-container{
    
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.footer-hero{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-hero img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}


.footer-hero span{
    font-size: 15px;
    font-weight: 500;
}

.footer-links{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.footer-links a{
    text-decoration: none;
    color: black;
    font-size: 12px;
    font-weight: 500;
    transition: opacity 200ms linear; 
}

.footer-links a:hover{
    opacity: .6;
}


/*media Query*/
@media only screen and (min-width: 300px) and (max-width: 350px){
   
    .footer-container {
        padding: 10px 5px 40px;
        flex-direction: column;
        gap: 10px;
    }

    .footer-hero{
        flex-direction: column;
        gap: 8px;
    }

   
    .footer-hero img{
        width: 40px;
        height: 40px;
    }

    .footer-hero span{
        
        font-size: 13px;
        font-weight: 400;
    }

    .footer-links a{
        font-weight: 400;
        font-size: 14px;
    }



}

@media only screen and (min-width: 350px) and (max-width: 600px){
   
    .footer-container {
        padding: 10px 10px 40px;
        flex-direction: column;
        gap: 10px;
    }

    .footer-hero{
        flex-direction: column;
        gap: 8px;
    }

   
    .footer-hero img{
        width: 50px;
        height: 50px;
    }

    .footer-hero span{
        font-size: 13px;
        font-weight: 400;
    }

    .footer-links a{
        font-weight: 400;
        font-size: 14px;
    }




}